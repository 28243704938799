import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Anasayfa from "./Pages/Anasayfa";
import Kurumsal from "./Pages/Kurumsal";
import Sistemler from "./Pages/Sistemler";
import FaaliyetAlanlari from "./Pages/FaaliyetAlanlari";
import referanslar from "./data/referanslar.json";
import ReferansProje from "./Pages/ReferansProje";
import sistemler from "./data/sistemler.json";
import faaliyetler from "./data/faaliyetler.json";
import Sistem from "./Pages/Sistem";
import Faaliyet from "./Pages/Faaliyet";
// import RoyalEpoksiSistem from "./Pages/RoyalEpoksiSistem";
// import OurHistory from "./Pages/OurHistory";
// import FontIcons from "./Pages/FontIcons";
import Error from "./Pages/Error";
import Referanslarimiz from "./Pages/Referanslarimiz";
// import ProjectGridNoGap3 from "./Pages/ProjectGridNoGap3";
// import ProjectGrid4 from "./Pages/ProjectGrid4";
// import ProjectGridNoGap4 from "./Pages/ProjectGridNoGap4";
// import ProjectGrid5 from "./Pages/ProjectGrid5";
// import ProjectGridNoGap5 from "./Pages/ProjectGridNoGap5";
// import ProjectMasonary3 from "./Pages/ProjectMasonary3";
// import ProjectMasonaryNoGap3 from "./Pages/ProjectMasonaryNoGap3";
// import ProjectMasonary4 from "./Pages/ProjectMasonary4";
// import ProjectMasonaryNoGap4 from "./Pages/ProjectMasonaryNoGap4";
// import ProjectMasonary5 from "./Pages/ProjectMasonary5";
// import ProjectMasonaryNoGap5 from "./Pages/ProjectMasonaryNoGap5";
// import ProjectCorousel from "./Pages/ProjectCorousel";
// import ProjectDetail1 from "./Pages/ProjectDetail1";
// import AbdiIbrahim from "./Pages/Referanslar/AbdiIbrahim";
// import BlogGrid from "./Pages/BlogGrid";
// import BlogListing from "./Pages/BlogListing";
// import BlogMasonary from "./Pages/BlogMasonary";
// import BlogSingle from "./Pages/BlogSingle";
// import PostRightSidebar from "./Pages/PostRightSidebar";
// import Faq from "./Pages/Faq";
import Iletisim from "./Pages/Iletisim";
import ScrollToTop from "./Common/ScrollToTop";
import SlugHandler from "./SlugHandler"; // Import SlugHandler

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <ScrollToTop />
        <div className="page-wraper">
          <Routes>
            <Route path="/" element={<Anasayfa />} />
            <Route path="/kurumsal" element={<Kurumsal />} />
            <Route path="/sistemler" element={<Sistemler />} />
            <Route path="/faaliyet-alanlari" element={<FaaliyetAlanlari />} />
            {/* <Route
              path="/royal-epoksi-sistem"
              element={<RoyalEpoksiSistem />}
            /> */}
            {/* <Route path="/our-history" element={<OurHistory />} /> */}
            {/* <Route path="/icon-font" element={<FontIcons />} /> */}
            <Route path="/error-404" element={<Error />} />
            <Route path="/referanslarimiz" element={<Referanslarimiz />} />
            {/* <Route
              path="/project-grid-3-columns-no-gap"
              element={<ProjectGridNoGap3 />}
            /> */}
            {/* <Route path="/project-grid-4-columns" element={<ProjectGrid4 />} /> */}
            {/* <Route
              path="/project-grid-4-columns-no-gap"
              element={<ProjectGridNoGap4 />}
            /> */}
            {/* <Route path="/project-grid-5-columns" element={<ProjectGrid5 />} />
            <Route
              path="/project-grid-5-columns-no-gap"
              element={<ProjectGridNoGap5 />}
            />
            <Route
              path="/project-masonry-3-columns"
              element={<ProjectMasonary3 />}
            />
            <Route
              path="/project-masonry-3-columns-no-gap"
              element={<ProjectMasonaryNoGap3 />}
            />
            <Route
              path="/project-masonry-4-columns"
              element={<ProjectMasonary4 />}
            />
            <Route
              path="/project-masonry-4-columns-no-gap"
              element={<ProjectMasonaryNoGap4 />}
            />
            <Route
              path="/project-masonry-5-columns"
              element={<ProjectMasonary5 />}
            />
            <Route
              path="/project-masonry-5-columns-no-gap"
              element={<ProjectMasonaryNoGap5 />}
            /> */}
            {/* <Route path="/project-carousel" element={<ProjectCorousel />} /> */}
            {/* <Route path="/project-detail1" element={<ProjectDetail1 />} /> */}
            {/* <Route path="/abdi-ibrahim" element={<AbdiIbrahim />} /> */}
            {referanslar.map((proje) => (
              <Route
                key={proje.projeid}
                path={`/${proje.slug}`}
                element={<ReferansProje proje={proje} />}
              />
            ))}
            {sistemler.map((sistem) => (
              <Route
                key={sistem.slug}
                path={`/${sistem.slug}`}
                element={<Sistem sistem={sistem} />}
              />
            ))}

            {faaliyetler.map((faaliyet) => (
              <Route
                key={faaliyet.slug}
                path={`/${faaliyet.slug}`}
                element={<Faaliyet faaliyet={faaliyet} />}
              />
            ))}

            <Route path="*" element={<SlugHandler />} />
            {/* <Route path="/blog-grid" element={<BlogGrid />} />
            <Route path="/blog-listing" element={<BlogListing />} />
            <Route path="/blog-masonry" element={<BlogMasonary />} />
            <Route path="/blog-single" element={<BlogSingle />} />
            <Route path="/post-right-sidebar" element={<PostRightSidebar />} /> */}
            {/* <Route path="/faq" element={<Faq />} /> */}
            <Route path="/iletisim" element={<Iletisim />} />
            {/* <Route path="" element={<Error />} /> */}
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default Components;
