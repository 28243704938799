import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";

class Error extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>404 - Sayfa Bulunamadı | SFS Floor</title>
          <meta
            name="description"
            content="Aradığınız sayfa bulunamadı. Lütfen ana sayfaya dönün veya menüyü kullanarak gezinin."
          />
          <meta
            name="keywords"
            content="404, sayfa bulunamadı, SFS Floor, hata, sayfa yok"
          />
        </Helmet>
        <Header />
        <div className="page-content">
          <Banner
            title="Page Not Found"
            pagename="Page Not Found"
            description="Sayfa Bulunamadı"
            bgimage="/assets/media/images/error2.jpg"
          />
          {/* SECTION CONTENT START */}
          <div
            className="section-full mobile-page-padding p-tb150 bg-bottom-left bg-no-repeat"
            style={{ backgroundImage: "url(images/background/bg-4.png)" }}
          >
            <div className="container">
              <div className="section-content">
                <div className="page-notfound row">
                  <div className="col-md-7">
                    <img
                      src={require("./../../images/error-404.png")}
                      alt="404 Error"
                    />
                  </div>
                  <div className="col-md-5">
                    <strong>Page Not Found</strong>
                    <span className="title">Error 404</span>
                    <p>
                      The Page Requested Could not be found. We're working on
                      it.
                    </p>
                    <NavLink
                      to={"/"}
                      title="Back to home"
                      className="site-button btn-half"
                    >
                      <span> Back to home</span>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>
        <Footer />
      </>
    );
  }
}

export default Error;
