import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import HakkimizdaElement from "../Elements/HakkimizdaElement";
// import WhatWeDo6 from "./../Elements/WhatWeDo6";
// import Achievements1 from "../Elements/Achievements1";
// import Testimonials2 from "./../Elements/Testimonials2";
// import ClientsLogo2 from "./../Elements/ClientsLogo2";
import OneCikanProjeler from "../Elements/OneCikanProjeler";

// var bnrimg = require("./../../images/banner/15.jpg");
var bnrimg = "/assets/media/images/kurumsal.jpg";

class Kurumsal extends React.Component {
  async componentDidMount() {
    async function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
      });
    }

    try {
      await loadScript("./assets/js/custom.js");
      console.log("js loaded successfully");
    } catch (error) {
      console.error("Error loading js", error);
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>SFS Floor - Kurumsal | Endüstriyel Zemin Kaplamaları</title>
          <meta
            name="description"
            content="SFS Floor'un kurumsal yapısı hakkında bilgi alın. Endüstriyel zemin kaplamaları alanındaki tecrübemizi ve hizmetlerimizi keşfedin."
          />
          <meta
            name="keywords"
            content="SFS Floor, kurumsal, endüstriyel zemin kaplamaları, dekoratif kaplamalar, Türkiye, Maldivler, Rusya, Suudi Arabistan"
          />
        </Helmet>
        <Header />
        <div className="page-content">
          <Banner
            title="Kurumsal"
            pagename="Kurumsal"
            description=""
            bgimage={bnrimg}
          />
          <HakkimizdaElement />
          {/* <WhatWeDo6 /> */}
          {/* <Achievements1 /> */}
          {/* <Testimonials2 separatoralignment="separator-center" /> */}

          <OneCikanProjeler
            alignment="separator-left"
            title="Öne Çıkan Projelerimiz"
            bgcolor="bg-white"
          />
        </div>

        <Footer />
      </>
    );
  }
}

export default Kurumsal;
