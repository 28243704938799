import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import FaaliyetElement from "../Elements/FaaliyetElement";
// import About3 from "../Elements/About3";
import Statistics1 from "../Elements/Statistics1";
// import Testimonials2 from "../Elements/Testimonials2";

// var bnrimg = require("./../../images/banner/5.jpg");
var bnrimg = "/assets/media/images/faaliyet-alanlari.jpg";

class Service2 extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            SFS Floor - Faaliyet Alanları | Endüstriyel Zemin Kaplamaları
          </title>
          <meta
            name="description"
            content="SFS Endüstriyel Zemin Kaplamaları olarak, birçok farklı alanda hizmet vermekteyiz. Faaliyet alanlarımızı inceleyebilirsiniz."
          />
          <meta
            name="keywords"
            content="SFS Floor, faaliyet alanları, endüstriyel zemin kaplamaları, dekoratif kaplamalar, epoksi, poliüretan, mikrobeton, beton zemin kaplamaları, zemin çözümleri"
          />
        </Helmet>
        <Header />
        <div className="page-content">
          <Banner
            title="Faaliyet Alanları"
            pagename="Faaliyet Alanları"
            description="SFS Endüstriyel Zemin Kaplamaları olarak, birçok farklı alanda hizmet vermekteyiz. Faaliyet alanlarımızı inceleyebilirsiniz."
            bgimage={bnrimg}
          />
          <FaaliyetElement />
          {/* <About3 bgcolor="bg-white" /> */}
          <Statistics1 />
          {/* <Testimonials2 separatoralignment="separator-left" /> */}
        </div>

        <Footer />
      </>
    );
  }
}

export default Service2;
