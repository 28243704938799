import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Components from "./components/Components";
import ScrolToTop from "./components/Elements/ScrolToTop";
import Loader from "./components/Elements/Loader";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const App = () => {
  useEffect(() => {
    ReactGA.initialize("G-948T7NBELW");
    ReactGA.send("pageview");
  }, []);

  return (
    <div className="App">
      <Components />
      <ScrolToTop />
      <Loader />
    </div>
  );
};

export default App;
