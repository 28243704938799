import React from "react";
import { NavLink } from "react-router-dom";
import sistemler from "./../data/sistemler.json";

var bgimg1 = require("./../../images/background/bg-5.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

class Services6 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding bg-gray  p-t80 p-b50 bg-repeat overflow-hide"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            <div className="large-title-block text-center">
              {/* TITLE START */}
              <div className="section-head">
                <div className="sx-separator-outer separator-left">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                  >
                    <h3 className="sep-line-one">Sistemler</h3>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
            </div>
            <div className="section-content">
              <div className="row number-block-two-outer">
                {sistemler.map((item, index) => {
                  const shortDesc =
                    item.shortDesc.length > 150
                      ? item.shortDesc.substring(0, 150) + "..."
                      : item.shortDesc;

                  return (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 m-b30"
                      key={index}
                    >
                      <div className="number-block-two animate-in-to-top bdr-gray-light bdr-solid bdr-1">
                        <div className="figcaption bg-white  p-a30">
                          <h4 className="m-t0">{item.name}</h4>
                          <p>{shortDesc}</p>
                          <NavLink
                            to={`/${item.slug}`}
                            className="site-button-link"
                          >
                            Devamı...
                          </NavLink>
                          <div className="figcaption-number animate-in-to-top-content">
                            <span>{item.count}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Services6;
