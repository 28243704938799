import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";

const ReferansProje = ({ proje }) => {
  useEffect(() => {
    function loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", () => resolve());
        script.addEventListener("error", (e) => reject(e));
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("/assets/js/custom.js");
  }, []);

  if (!proje) {
    return <div>Proje bulunamadı</div>;
  }

  const imagePath = "/assets/media/images/referanslar/";

  return (
    <>
      <Helmet>
        <title>{`SFS Floor - ${proje.projeadi} | Endüstriyel Zemin Kaplamaları`}</title>
        <meta name="description" content={proje.desc} />
        <meta name="keywords" content={proje.keywords} />
      </Helmet>
      <Header />
      <div className="page-content">
        <Banner
          title={proje.projeadi}
          pagename={proje.projeadi}
          description={proje.shortdesc}
          bgimage={`${imagePath}${proje.slug}/kapak.jpg`}
          type="ref"
        />

        <div className="section-full p-tb80 inner-page-padding stick_in_parent">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="project-detail-outer row">
                  <div className="col-md-6">
                    <div className="project-detail-pic m-b30">
                      <div className="sx-media">
                        <img src={`${imagePath}${proje.slug}/1.jpg`} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="project-detail-pic m-b30">
                      <div className="sx-media">
                        <img src={`${imagePath}${proje.slug}/2.jpg`} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="project-detail-pic m-b30">
                      <div className="sx-media">
                        <img src={`${imagePath}${proje.slug}/3.jpg`} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="project-detail-pic m-b30">
                      <div className="sx-media">
                        <img src={`${imagePath}${proje.slug}/4.jpg`} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="project-detail-pic m-b30">
                      <div className="sx-media">
                        <img src={`${imagePath}${proje.slug}/5.jpg`} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="project-detail-pic m-b30">
                      <div className="sx-media">
                        <img src={`${imagePath}${proje.slug}/6.jpg`} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12"></div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 sticky_column">
                <div className="project-detail-containt-2">
                  <div className="bg-white text-black p-a20 shadow">
                    <div className="product-block bg-gray p-a30 m-b30">
                      <ul>
                        {proje.yil !== "" && (
                          <li>
                            <h4 className="sx-title">Tarih</h4>
                            <p>{proje.yil}</p>
                          </li>
                        )}

                        {/* <li>
                          <h4 className="sx-title">İş Ortağı</h4>
                          <p>{proje.projeadi}</p>
                        </li> */}
                        {/* <li>
                          <h4 className="sx-title">Uygulamalar</h4>
                          <p>
                            Döküm Epoksi, Epoksi Terrazzo, Poliüretan Döküm
                            Epoksi, Elyaf Laminasyon
                          </p>
                        </li> */}

                        {proje.lokasyon !== "" && (
                          <li>
                            <h4 className="sx-title">Konum</h4>
                            <p>{proje.lokasyon}</p>
                          </li>
                        )}
                        {proje.metrekare !== null && (
                          <li>
                            <h4 className="sx-title">Metraj</h4>
                            <p>{proje.metrekare} m2</p>
                          </li>
                        )}
                      </ul>
                    </div>
                    {proje.caption !== "" && <h4>{proje.caption}</h4>}

                    {proje.desc !== "" && <p>{proje.desc}</p>}
                    <div className="m-b0">
                      <div className="sx-divider divider-1px bg-black">
                        <i className="icon-dot c-square" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReferansProje;
