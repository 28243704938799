import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";

var bnrimg = require("./../../images/main-slider/slider1/slide1.jpg");

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ContactUs extends React.Component {
  render() {
    // const defaultProps = {
    //   center: {
    //     lat: 34.07328,
    //     lng: -118.25141,
    //   },
    //   zoom: 12,
    // };
    return (
      <>
        <Helmet>
          <title>SFS Floor - İletişim | Endüstriyel Zemin Kaplamaları</title>
          <meta
            name="description"
            content="SFS Endüstriyel Zemin Kaplamaları olarak, her türlü soru ve görüşleriniz için bizimle iletişime geçebilirsiniz. Telefon, e-posta ve adres bilgilerimiz için sayfamızı ziyaret edin."
          />
          <meta
            name="keywords"
            content="SFS Floor, iletişim, telefon, e-posta, adres, endüstriyel zemin kaplamaları, dekoratif kaplamalar, İstanbul, Türkiye"
          />
        </Helmet>
        <Header />
        <div className="page-content">
          <Banner
            title="Bize Ulaşın"
            pagename="İletişim"
            description="SFS Endüstriyel Zemin Kaplamaları olarak, her türlü soru ve görüşleriniz için bizimle iletişime geçebilirsiniz."
            bgimage={bnrimg}
          />
          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding">
            {/* LOCATION BLOCK */}
            <div className="container">
              {/* GOOGLE MAP & CONTACT FORM */}
              <div className="section-content">
                {/* CONTACT FORM */}
                <div className="row">
                  <div className="col-lg-8 col-md-12 col-sm-12">
                    <form
                      className="contact-form cons-contact-form bg-gray p-a30"
                      method="post"
                      action="#"
                    >
                      <div className="contact-one">
                        {/* TITLE START */}
                        <div className="section-head">
                          <div className="sx-separator-outer separator-left">
                            <div
                              className="sx-separator bg-white bg-moving bg-repeat-x"
                              style={{
                                backgroundImage:
                                  "url(images/background/cross-line2.png)",
                              }}
                            >
                              <h3 className="sep-line-one">İletişim Formu</h3>
                            </div>
                          </div>
                        </div>
                        {/* TITLE END */}
                        <div className="form-group">
                          <input
                            name="username"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Adınız Soyadınız"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="email"
                            type="text"
                            className="form-control"
                            required
                            placeholder="E-Posta"
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            name="message"
                            rows={4}
                            className="form-control "
                            required
                            placeholder="Mesajınızı buraya yazınız..."
                            defaultValue={""}
                          />
                        </div>
                        <div className="text-right">
                          <button
                            name="submit"
                            type="button"
                            value="Submit"
                            className="site-button btn-half"
                          >
                            <span> Gönder</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div
                      className="contact-info block-shadow bg-white bg-center p-a40"
                      style={{
                        backgroundImage: "url(images/background/bg-map.png)",
                      }}
                    >
                      <div>
                        {/* TITLE START */}
                        <div className="section-head">
                          <div className="sx-separator-outer separator-left">
                            <div
                              className="sx-separator bg-white bg-moving bg-repeat-x"
                              style={{
                                backgroundImage:
                                  "url(images/background/cross-line2.png)",
                              }}
                            >
                              <h3 className="sep-line-one">
                                İletişim Bilgileri
                              </h3>
                            </div>
                          </div>
                        </div>
                        {/* TITLE END */}
                        <div className="sx-icon-box-wraper left p-b30">
                          <div className="icon-xs">
                            <i className="fa fa-phone" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0">Telefon</h5>
                            <p>
                              <a
                                href="tel:+902164224295"
                                className="no-decoration"
                              >
                                +90 (216) 422-4295
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="sx-icon-box-wraper left p-b30">
                          <div className="icon-xs">
                            <i className="fa fa-envelope" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0">E-Posta</h5>
                            <p>
                              <a
                                href="mailto:info@sfsfloor.com"
                                className="no-decoration"
                              >
                                info@sfsfloor.com
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="sx-icon-box-wraper left">
                          <div className="icon-xs">
                            <i className="fa fa-map-marker" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0">Adres</h5>
                            <p>
                              Ferah Mah. Mehmet Akif Ersoy Cad. No:246/9,
                              Üsküdar, İstanbul
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="gmap-outline">
            {/* <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDqv3ZoP1I2RqU4z5c_ZKWbOqPbCv97ZG8",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              <AnyReactComponent
                lat={34.07328}
                lng={-118.25141}
                text={<i className="fa fa-map-marker" />}
              />
            </GoogleMapReact> */}
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ContactUs;
