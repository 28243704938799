import React from "react";
import Navigation from "./Navigation";
import { NavLink } from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { logo: require("./../../images/logo-1.png") };
  }

  componentDidMount() {
    const handleScroll = () => {
      const offset = window.scrollY;

      const stickyheader = document.querySelector(".sticky-header ");

      if (offset >= 100) {
        stickyheader.classList.add("is-fixed");
        stickyheader.classList.add("color-fill");
      } else {
        stickyheader.classList.remove("is-fixed");
        stickyheader.classList.remove("color-fill");
      }
    };

    window.addEventListener("scroll", handleScroll);

    window.updateTopMostParent = (logopath) => {
      this.setState({ logo: logopath });
    };
  }

  render() {
    return (
      <>
        <header className="site-header nav-wide nav-transparent mobile-sider-drawer-menu">
          <div className="top-bar sx-bg-secondry">
            <div className="container-fluid">
              <div className="d-flex justify-content-between ">
                <ul className="list-unstyled social-bx text-white d-flex flex-wrap align-content-center">
                  <li>
                    <a
                      href="https://www.facebook.com/sfsfloorsolutions/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/sfsfloor/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul>
                <ul className="list-unstyled e-p-bx text-white">
                  <li>
                    <span></span>
                    <a
                      href="mailto:info@sfsfloor.com"
                      className="text-white no-decoration"
                    >
                      info@sfsfloor.com
                    </a>
                  </li>
                  <li>
                    <span></span>
                    <a
                      href="tel:+902164224295"
                      className="text-white no-decoration"
                    >
                      +90 (216) 422-4295
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="sticky-header main-bar-wraper navbar-expand-lg">
            <div className="main-bar">
              <div className="container-fluid clearfix">
                <div className="logo-header">
                  <div className="logo-header-inner logo-header-one">
                    <NavLink to={"/"}>
                      <img src={this.state.logo} alt="Sfsfloor" />
                    </NavLink>
                  </div>
                </div>
                {/* NAV Toggle Button */}
                <button
                  id="mobile-side-drawer"
                  data-target=".header-nav"
                  data-toggle="collapse"
                  type="button"
                  className="navbar-toggler collapsed"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar icon-bar-first" />
                  <span className="icon-bar icon-bar-two" />
                  <span className="icon-bar icon-bar-three" />{" "}
                </button>

                {/* MAIN NAVIGATION */}
                <div className="header-nav nav-dark navbar-collapse collapse justify-content-end collapse">
                  <Navigation />
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;
