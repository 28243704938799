import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import referanslar from "./data/referanslar.json";
import ReferansProje from "./Pages/ReferansProje";

const SlugHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const validSlugs = referanslar.map((proje) => proje.slug);
  const currentPath = location.pathname.slice(1); // Remove leading "/"

  useEffect(() => {
    if (!validSlugs.includes(currentPath)) {
      navigate("/error-404");
    }
  }, [currentPath, navigate, validSlugs]);

  const selectedProje = referanslar.find((proje) => proje.slug === currentPath);
  return selectedProje ? <ReferansProje proje={selectedProje} /> : null;
};

export default SlugHandler;
