import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import referanslar from "./../data/referanslar.json";

const filters = [
  { label: "Oteller", filter: ".cat-otel" },
  { label: "Hastaneler", filter: ".cat-hastane" },
  { label: "Fabrikalar", filter: ".cat-fabrika" },
  { label: "Okullar", filter: ".cat-okul" },
  { label: "Tema Parklar", filter: ".cat-temapark" },
  // { label: "Restoran ve Cafeler", filter: ".cat-restoran" },
  { label: "Özel Konutlar", filter: ".cat-ev" },
  { label: "Alışveriş Merkezleri", filter: ".cat-avm" },
];

// var bnrimg = require("./../../images/banner/3.jpg");
var bnrimg = "/assets/media/images/referanslar.jpg";
// var bgimg1 = require("./../../images/background/cross-line.png");

class Referanslarimiz extends React.Component {
  async componentDidMount() {
    async function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
      });
    }

    try {
      await loadScript("./assets/js/custom.js");
      console.log("js loaded successfully");
    } catch (error) {
      console.error("Error loading js", error);
    }
  }

  scrollToReferansList = () => {
    const element = document.getElementById("referans-list");
    const offset = 90; // Header boyutunu buraya ekleyin
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    const imagePath = "/assets/media/images/referanslar/";
    const filteredReferanslar = referanslar.filter((item) => item.order < 1000);
    const sortedReferanslar = referanslar.sort((a, b) => a.order - b.order);

    return (
      <>
        <Helmet>
          <title>
            SFS Floor - Referanslarımız | Endüstriyel Zemin Kaplamaları
          </title>
          <meta
            name="description"
            content="SFS Floor'un tamamladığı projeleri ve referanslarımızı inceleyin. Endüstriyel zemin kaplamaları ve dekoratif kaplamalar alanında geniş bir yelpazede hizmet sunuyoruz."
          />
          <meta
            name="keywords"
            content="SFS Floor, referanslar, projeler, endüstriyel zemin kaplamaları, dekoratif kaplamalar, oteller, hastaneler, fabrikalar, okullar, tema parklar, restoranlar, özel konutlar, alışveriş merkezleri"
          />
        </Helmet>
        <Header />
        <div className="page-content">
          <Banner
            title="Referanslarımız"
            pagename="Referanslarımız"
            description=""
            bgimage={bnrimg}
          />
          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              {/* Mobilde gösterilecek buton */}
              <div className="text-center d-lg-none p-b30">
                <button
                  className="site-button btn-half"
                  onClick={this.scrollToReferansList}
                >
                  Tüm Referanslarımızı Göster
                </button>
              </div>
              <div className="row">
                <div className="col-lg-9">
                  {/* Filter Nav START */}
                  <div className="filter-wrap p-b30 text-center">
                    <ul className="filter-navigation masonry-filter clearfix">
                      <li className="active">
                        <NavLink
                          to={"#"}
                          className="btn from-top"
                          data-filter="*"
                          data-hover="All"
                        >
                          Başlıca
                        </NavLink>
                      </li>
                      {filters.map((item, index) => (
                        <li key={index}>
                          <NavLink
                            to={"#"}
                            className="btn from-top"
                            data-filter={item.filter}
                          >
                            {item.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* Filter Nav END */}
                  {/* GALLERY CONTENT START */}
                  <ul className="masonry-outer work-grid row clearfix list-unstyled">
                    {/* mfp-gallery */}
                    {filteredReferanslar
                      .sort((a, b) => a.order - b.order)
                      .map((item, index) => (
                        <div
                          key={index}
                          className={`${item.category} masonry-item col-lg-4 col-md-6 col-sm-12 m-b30`}
                        >
                          <div className="sx-box image-hover-block">
                            <div className="sx-thum-bx">
                              <img
                                src={`${imagePath}${item.slug}/1.jpg`}
                                alt=""
                              />
                            </div>
                            <div className="sx-info p-t20 text-white">
                              <h4 className="sx-tilte">
                                <NavLink to={`/${item.slug}`}>
                                  {item.projeadi}
                                </NavLink>
                              </h4>
                              <p className="m-b0">{item.lokasyon}</p>
                            </div>
                            {/* <a className="mfp-link" href={item.image}> */}
                            <a className="mfp-link" href={`/${item.slug}`}>
                              <i className="fa fa-arrows-alt" />
                            </a>
                          </div>
                        </div>
                      ))}
                  </ul>
                  {/* GALLERY CONTENT END */}
                </div>
                <div className="col-lg-3">
                  <div className="other-referanslar" id="referans-list">
                    <h4>Referanslarımız</h4>
                    <ul className="list-unstyled">
                      {sortedReferanslar.map((item, index) => (
                        <li key={index} className="p-b10">
                          {item.order < 1000 ? (
                            <NavLink to={`/${item.slug}`}>
                              {item.projeadi}{" "}
                              <i className="fa fa-arrow-right" />
                            </NavLink>
                          ) : (
                            <span>{item.projeadi}</span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div
                className="text-center load-more-btn-outer"
                style={{ backgroundImage: "url(" + bgimg1 + ")" }}
              >
                <button className="site-button-secondry btn-half">
                  <span>Devamı...</span>
                </button>
              </div> */}
            </div>
          </div>
          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default Referanslarimiz;
