import React from "react";
import { NavLink } from "react-router-dom";

import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import AttractionsOutlinedIcon from "@mui/icons-material/AttractionsOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import BrushOutlinedIcon from "@mui/icons-material/BrushOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import RestaurantOutlinedIcon from "@mui/icons-material/RestaurantOutlined";

const services = [
  {
    count: "01",
    title: "Oteller",
    flaticon: HotelOutlinedIcon,
    description:
      "Otel konseptinize uygun şık ve dayanıklı zeminlerle konuklarınıza unutulmaz bir deneyim sunuyoruz.",
  },
  {
    count: "02",
    title: "Restoran ve Cafeler",
    flaticon: RestaurantOutlinedIcon,
    description:
      "Atmosferinizi yansıtan şık ve dayanıklı zeminlerle mekanlarınıza özgün bir tarz kazandırıyoruz.",
  },
  {
    count: "03",
    title: "Özel Konutlar",
    flaticon: CottageOutlinedIcon,
    description:
      "Özel konutlarınıza kişisel tarzınızı yansıtan estetik ve konforlu zemin seçenekleri sunuyoruz.",
  },

  {
    count: "04",
    title: "Hastaneler",
    flaticon: LocalHospitalOutlinedIcon,
    description:
      "Hijyen standartlarına uygun ve rahatlatıcı dekoratif zeminlerle hastanelerinizde sağlık alanında estetik bir atmosfer oluşturuyoruz.",
  },
  {
    count: "05",
    title: "Fabrikalar",
    flaticon: FactoryOutlinedIcon,
    description:
      "Verimliliği ve iş güvenliğini ön planda tutan dekoratif zemin sistemleri ile fabrikalarınıza estetik ve fonksiyonellik katıyoruz.",
  },

  {
    count: "06",
    title: "Alışveriş Merkezleri",
    flaticon: LocalMallOutlinedIcon,
    description:
      "Alışveriş merkezlerinizi çağdaş ve çekici hale getiren dekoratif zemin çözümleri sunarak alışveriş deneyimini iyileştiriyoruz.",
  },
  {
    count: "07",
    title: "Tema Parklar",
    flaticon: AttractionsOutlinedIcon,
    description:
      "Eğlence ve macera dolu temalarınıza uygun renkli ve dayanıklı zeminlerle tema parklarınıza özgü bir atmosfer katıyoruz.",
  },
  {
    count: "08",
    title: "Okullar",
    flaticon: SchoolOutlinedIcon,
    description:
      "Eğitim ortamlarınıza uygun, dayanıklı ve kolay bakım sağlayan dekoratif zeminlerle öğrenci deneyimini geliştiriyoruz.",
  },
  {
    count: "09",
    title: "Mağazalar",
    flaticon: LocalMallOutlinedIcon,
    description:
      "Mağazalarınıza uygun, dayanıklı ve estetik zeminlerle müşteri deneyimini iyileştiriyoruz.",
  },
  {
    count: "10",
    title: "Ofisler ve İşletmeler",
    flaticon: BusinessOutlinedIcon,
    description:
      "Ofisleriniz ve işletmeleriniz için modern ve şık zeminlerle çalışanlarınızın verimliliğini artırıyoruz.",
  },
  {
    count: "11",
    title: "Sanat Galerileri",
    flaticon: BrushOutlinedIcon,
    description:
      "Sanat galerilerine uygun, estetik ve özgün zeminlerle sanat eserlerini ön plana çıkarıyoruz.",
  },
  {
    count: "12",
    title: "Temiz Odalar",
    flaticon: ScienceOutlinedIcon,
    description:
      "Hijyen standartlarına uygun ve rahatlatıcı dekoratif zeminlerle temiz odalarınıza estetik bir atmosfer oluşturuyoruz.",
  },
];

// var bgimg1 = require("./../../images/background/cross-line2.png");

class Services1 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80  p-b50 bg-gray">
          <div className="section-content">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head">
                <div className="sx-separator-outer separator-left">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    // style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                  >
                    <h3 className="sep-line-one ">Faaliyet Alanlarımız</h3>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              <div className="row">
                {services
                  .slice(0, this.props.pgname === "ana" ? 8 : services.length)
                  .map((item, index) => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 m-b30"
                      key={index}
                    >
                      <div className="sx-icon-box-wraper icon-count-2-outer">
                        <div className="icon-count-2 bg-white">
                          <span className="icon-count-number">
                            {item.count}
                          </span>
                          <div className="icon-xl inline-icon m-b5 scale-in-center">
                            <span className="icon-cell">
                              <i>
                                <item.flaticon style={{ fontSize: 50 }} />
                              </i>
                            </span>
                          </div>
                          <div className="icon-content">
                            <h4 className="sx-tilte">{item.title}</h4>
                            <p>{item.description}</p>
                            <div className="text-left">
                              <NavLink
                                to={"/faaliyet-alanlari"}
                                className="site-button-link"
                              >
                                Daha Fazla
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {this.props.pgname === "ana" && (
                  <div className="col-lg-4 col-md-6 col-sm-12 m-b30" key="999">
                    <div className="sx-icon-box-wraper  icon-count-2-outer">
                      <div className="icon-count-2 bg-white">
                        <span className="icon-count-number">09</span>
                        <div className="icon-xl inline-icon m-b5 scale-in-center">
                          <span className="icon-cell">
                            <i>
                              <DesignServicesOutlinedIcon
                                style={{ fontSize: 50 }}
                              />
                            </i>
                          </span>
                        </div>
                        <div className="icon-content">
                          <h4 className="sx-tilte">Diğer</h4>
                          <p>
                            Dekoratif zemin sistemleri konusunda geniş bir
                            yelpazede hizmet vererek, her sektöre ve ihtiyaca
                            özel çözümler üretiyoruz.
                          </p>
                          <div className="text-left">
                            <NavLink
                              to={"/faaliyet-alanlari"}
                              className="site-button-link"
                            >
                              Daha Fazla
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>FAALİYET ALANLARI</strong>
          </div>
        </div>
      </>
    );
  }
}

export default Services1;
