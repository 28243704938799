import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import OwlCarouselSlider from "../Elements/OwlCarouselSlider";
import BizKimiz from "../Elements/BizKimiz";
import FaaliyetElement from "../Elements/FaaliyetElement";
import OneCikanProjeler from "./../Elements/OneCikanProjeler";
import ClientsLogo2 from "./../Elements/ClientsLogo2";
// import Achievements1 from "./../Elements/Achievements1";

class Anasayfa extends React.Component {
  async componentDidMount() {
    async function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
      });
    }

    try {
      await loadScript("./assets/js/custom.js");
      console.log("js loaded successfully");
    } catch (error) {
      console.error("Error loading js", error);
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>SFS Floor | Endüstriyel Zemin Kaplamaları</title>
          <meta
            name="description"
            content="SFS Floor, endüstriyel ve dekoratif zemin kaplamalarında 1974'ten beri hizmet veren bir aile şirketidir. Türkiye'den Maldivler'e, Rusya'dan Suudi Arabistan'a kadar geniş bir coğrafyada hizmet sunmaktayız."
          />
          <meta
            name="keywords"
            content="terrazzo, epoksi terrazzo, çimento terrazzo, prekast terrazzo, terrazzo desing, terrazzo flooring, terrazzo art, terrazzo tiles, terrazzo decor, epoksi kaplama, poliüretan epoksi, antistatik epoksi, stone carpet, havuz kaplama"
          />
        </Helmet>
        <Header />
        <div className="page-content">
          <OwlCarouselSlider />
          <BizKimiz />
          <FaaliyetElement pgname="ana" />
          <OneCikanProjeler
            alignment="separator-left"
            title="Öne Çıkan Projelerimiz"
            bgcolor="bg-white"
          />
          {/* <Achievements1 /> */}
          <ClientsLogo2 bgcolor="bg-white" />
        </div>
        <Footer />
      </>
    );
  }
}

export default Anasayfa;
