import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../Elements/Switcher";

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className="site-footer footer-large  footer-dark	footer-wide">
          {/* FOOTER BLOCKES START */}
          <div className="footer-top overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                {/* ABOUT COMPANY */}
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="widget widget_about">
                    {/*<h4 class="widget-title">About Company</h4>*/}
                    <div className="logo-footer clearfix p-b15">
                      <NavLink to={"/"}>
                        <img
                          src={require("./../../images/logo-1.png")}
                          alt="Sfsfloor"
                        />
                      </NavLink>
                    </div>
                    <p>
                      SFS Endüstriyel Zemin Kaplamaları olarak, 1997 yılından bu
                      yana hem Türkiye'de hem de dünya genelinde birçok
                      prestijli projeye imza attık. Uzman ekibimiz ve yenilikçi
                      çözümlerimizle, sektörde lider konumdayız. Geniş ürün
                      yelpazemizle kalite ve estetiği bir arada sunarak, müşteri
                      memnuniyetini en üst düzeyde tutmayı hedefliyoruz.
                    </p>
                    <ul className="social-icons  sx-social-links">
                      {/* <li>
                        <a
                          href="https://www.behance.net/"
                          className="fa fa-behance"
                          target="_blank"
                          rel="noreferrer"
                        ></a>
                      </li> */}
                      <li>
                        <a
                          href="https://www.facebook.com/sfsfloorsolutions/"
                          className="fa fa-facebook"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="sr-only">Facebook</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://linkedin.com/"
                          className="fa fa-linkedin"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="sr-only">LinkedIn</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/sfsfloor/"
                          className="fa fa-instagram"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="sr-only">Instagram</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* USEFUL LINKS */}
                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                  <div className="widget widget_services inline-links">
                    <h5 className="widget-title">Linkler</h5>
                    <ul>
                      <li>
                        <NavLink to={"/kurumsal"}>Kurumsal</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/referanslarimiz"}>
                          Referanslarımız
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/sistemler"}>Sistemler</NavLink>
                      </li>

                      <li>
                        <NavLink to={"/faaliyet-alanlari"}>
                          Faaliyet Alanları
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={"/"}>İnsan Kaynakları</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/iletisim"}>İletişim</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* CONTACT US */}
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_address_outer">
                    <h5 className="widget-title">İletişim</h5>
                    <ul className="widget_address">
                      <li>
                        Ferah Mah. Mehmet Akif Ersoy Cad. No:246/9, Üsküdar,
                        İstanbul
                      </li>
                      <li>
                        E-Posta:{" "}
                        <a
                          href="mailto:info@sfsfloor.com"
                          className="text-white no-decoration"
                        >
                          info@sfsfloor.com
                        </a>
                      </li>
                      <li>
                        Telefon:{" "}
                        <a
                          href="tel:+902164224295"
                          className="text-white no-decoration"
                        >
                          +90 (216) 422-4295
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                <div className="sx-footer-bot-left">
                  <span className="copyrights-text">
                    © 2024 SFS FLOOR - All Rights Reserved
                  </span>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <Switcher />
      </>
    );
  }
}

export default Footer;
