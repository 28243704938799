import React from "react";
// import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/projelerimiz/maldivler/kare/k5.jpg");

class HakkimizdaElement extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b80 bg-gray">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-12 ">
                  <div
                    className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                    style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                  ></div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-12">
                  <div className="about-home-2">
                    <h3 className="m-t0 sx-tilte">
                      Yılların Getirdiği Tecrübe ile Güçlü Adımlar
                    </h3>
                    <p>
                      1974 yılından itibaren inşaat sektörüne hizmet veren
                      ailenin, 1997 yılında kurulmuş olan ilk şirketi Doğu
                      Kardeşler Turizm ve İnşaat Şirketi'nin yanına, 2012
                      yılında SFS Endüstriyel Zemin Kaplamaları'nı ekleyerek,
                      hem endüstriyel hem de dekoratif kaplamalarda, yurt içi ve
                      yurt dışında hizmet vermeye devam etmektedir. Geçmişten
                      günümüze gelen bilgi, birikim ve tecrübe ile şu anda 3.
                      kuşak olarak yoluna devam etmektedir.
                    </p>
                    <div className="text-left">
                      {/* <NavLink to={"/about-2"} className="site-button-link">
                        Read More
                      </NavLink> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HakkimizdaElement;
