import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import Services6 from "../Elements/Services6";
import ClientsLogo2 from "../Elements/ClientsLogo2";

// var bnrimg = require("./../../images/banner/6.jpg");
var bnrimg = "/assets/media/images/sistemler.jpg";

class Sistemler extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>SFS Floor - Sistemler | Endüstriyel Zemin Kaplamaları</title>
          <meta
            name="description"
            content="SFS Endüstriyel Zemin Kaplamaları, sahip olduğu çeşitli sistemlerle farklı ihtiyaçlara yönelik çözümler sunmaktadır."
          />
          <meta
            name="keywords"
            content="SFS Floor, sistemler, endüstriyel zemin kaplamaları, dekoratif kaplamalar, epoksi, poliüretan, mikrobeton, beton zemin kaplamaları, zemin çözümleri"
          />
        </Helmet>
        <Header />
        <div className="page-content">
          <Banner
            title="Sistemler"
            pagename="Sistemler"
            description="SFS Endüstriyel Zemin Kaplamaları, sahip olduğu çeşitli sistemlerle farklı ihtiyaçlara yönelik çözümler sunmaktadır."
            bgimage={bnrimg}
          />
          <Services6 />
          <ClientsLogo2 bgcolor="bg-gray" />
        </div>
        <Footer />
      </>
    );
  }
}

export default Sistemler;
