import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";

const Sistem = ({ sistem }) => {
  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", () => resolve());
        script.addEventListener("error", (e) => reject(e));
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    };

    loadScript("/assets/js/custom.js");
  }, []);

  if (!sistem) {
    return <div>Sistem bulunamadı</div>;
  }

  const imagePath = "/assets/media/images/sistemler/";

  return (
    <>
      <Helmet>
        <title>{`SFS Floor - ${sistem.name} | Endüstriyel Zemin Kaplamaları`}</title>
        <meta name="description" content={sistem.shortDesc} />
        <meta name="keywords" content={sistem.keywords} />
      </Helmet>
      <Header />
      <div className="page-content">
        <Banner
          title={sistem.name}
          pagename={sistem.name}
          description={sistem.shortDesc}
          bgimage={`${imagePath}${sistem.slug}/kapak.jpg`}
          type="sys"
        />
        <div className="section-full p-t80 p-b50 mobile-page-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 sticky_column">
                <div className="about4-section m-b20 bg-gray p-a20">
                  <div className="widget widget_services">
                    <h4 className="widget-title">{sistem.urunSeriAdi}</h4>
                    <ul className="bg-white">
                      {sistem.urunSerisi.map((urun, index) => (
                        <li key={index}>
                          <NavLink to={`/${urun.slug}`}>{urun.isim}</NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                    <NavLink to={sistem.sistemPDF} className="btn-block">
                      <span className="text-black m-r10">
                        <i className="fa fa-file-pdf-o" />
                      </span>
                      <strong className="text-uppercase text-black">
                        Teknik Doküman .PDF
                      </strong>
                    </NavLink>
                  </div>
                  <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                    <NavLink to={sistem.sistemDoc} className="btn-block">
                      <span className="text-black m-r10">
                        <i className="fa fa-file-word-o" />
                      </span>
                      <strong className="text-uppercase text-black">
                        Teknik Doküman .DOC
                      </strong>
                    </NavLink>
                  </div>
                  <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                    <NavLink to={sistem.kullanimPDF} className="btn-block">
                      <span className="text-black m-r10">
                        <i className="fa fa-file-pdf-o" />
                      </span>
                      <strong className="text-uppercase text-black">
                        Kullanım Alanları .PDF
                      </strong>
                    </NavLink>
                  </div>
                  <div className="about4-content m-t30">
                    <h4>Diğer Tüm Sistemlerimiz</h4>
                    <p>
                      Diğer tüm sistemlerimizi incelemek için sistemler
                      sayfamızı ziyaret edebilirsiniz.
                    </p>
                    <NavLink to={"/sistemler"} className="site-button-link">
                      SİSTEMLER
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="section-content">
                  <div className="service-single-block m-b30">
                    <div className="sx-media">
                      <img
                        src={`${imagePath}${sistem.slug}/ana.jpg`}
                        alt={sistem.name}
                      />
                    </div>
                    <h3 className="m-t30 sx-tilte">{sistem.name}</h3>
                    <p>{sistem.shortDesc}</p>
                    <p>{sistem.longDesc}</p>
                    <h4 className="m-t30 sx-tilte">KULLANIM YERLERİ</h4>
                    <div className="single-service-list">
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <ul className="list-angle-right anchor-line">
                            {sistem.kullanimYerleri
                              .slice(
                                0,
                                Math.ceil(sistem.kullanimYerleri.length / 2)
                              )
                              .map((yer, index) => (
                                <li key={index}>{yer}</li>
                              ))}
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <ul className="list-angle-right anchor-line">
                            {sistem.kullanimYerleri
                              .slice(
                                Math.ceil(sistem.kullanimYerleri.length / 2)
                              )
                              .map((yer, index) => (
                                <li key={index}>{yer}</li>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* GALLERY CONTENT END */}
          </div>
        </div>
        {/* SECTION CONTENT END  */}
      </div>
      <Footer />
    </>
  );
};

export default Sistem;
