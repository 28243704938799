import React from "react";
import OwlCarousel from "react-owl-carousel";
import "./OwlCarouselSlider.css";
import slide1 from "./../../images/main-slider/slider1/01-abdi-ibrahim-2.jpg";
import slide2 from "./../../images/main-slider/slider1/02-acibadem-altunizade.jpg";
import slide3 from "./../../images/main-slider/slider1/03-land-of-legends.jpg";
import slide4 from "./../../images/main-slider/slider1/04-rixos-palm-dubai.jpg";
import slide5 from "./../../images/main-slider/slider1/05-koc-lisesi.jpg";
import slide6 from "./../../images/main-slider/slider1/06-kazan-palace.jpg";
import slide7 from "./../../images/main-slider/slider1/07-joali-maldives.jpg";
import slide8 from "./../../images/main-slider/slider1/08-acibadem-atasehir.jpg";
import slide9 from "./../../images/main-slider/slider1/09-rixos-premium-aktau-2.jpg";
import slide10 from "./../../images/main-slider/slider1/10-delphin-be-grand.jpg";
import slide11 from "./../../images/main-slider/slider1/11-kuru-cesme-divan.jpg";
import slide12 from "./../../images/main-slider/slider1/12-merit-park.jpg";

import { NavLink } from "react-router-dom";

const OwlCarouselSlider = () => {
  const options = {
    items: 1,
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    animateOut: "fadeOut",
    animateIn: "fadeIn",
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
  };

  return (
    <div id="main-slider">
      <OwlCarousel className="owl-theme" {...options}>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide7})` }}>
            <div className="overlay">
              <h2>MALDIVES</h2>
              <h1>JOALI ISLAND</h1>
              <p>
                Joali Maldives, Hotel@Lodge tarafından en iyi resort
                seçilmiştir. Maldivler'deki otelin tüm zemin epoksi terrazzo
                kaplamaları SFS Floor tarafından yapılmıştır.
              </p>
              <NavLink
                to="/joali-island-maldives"
                className="site-button btn-half"
              >
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide8})` }}>
            <div className="overlay">
              <h2>ATAŞEHİR</h2>
              <h1>ACIBADEM HASTANESİ</h1>
              <p>
                Acıbadem ATAŞEHİR hastanesinin tüm zemin epoksi kaplamaları ve
                epoksi terrazzo kaplaması SFS Floor tarafından yapılmıştır
              </p>
              <NavLink
                to="/acibadem-hastanesi-atasehir"
                className="site-button btn-half"
              >
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide2})` }}>
            <div className="overlay">
              <h2>ALTUNİZADE</h2>
              <h1>ACIBADEM HASTANESİ</h1>
              <p>
                Tüm Acıbadem hastanelerinin zemin epoksi kaplama ve terrazzo
                uygulamaları SFS Floor tarafından yapılmaktadır.
              </p>
              <NavLink
                to="/acibadem-hastanesi-altunizade"
                className="site-button btn-half"
              >
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide3})` }}>
            <div className="overlay">
              <h2>ANTALYA</h2>
              <h1>LAND OF LEGENDS</h1>
              <p>
                The Land of Legends'ın tüm havuzları, yürüyüş yolları, tema
                parklar, restorantlar ve ortak kullanım alanlarının zemin
                kaplamaları SFS Floor tarafından yapılmıştır.
              </p>
              <NavLink
                to="/the-land-of-legends"
                className="site-button btn-half"
              >
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide4})` }}>
            <div className="overlay">
              <h2>DUBAI</h2>
              <h1>RIXOS PALM</h1>
              <p>
                Dubai The Palm Rixos otelinin zemin terrazzo uygulamaları SFS
                Floor tarafından yapılmıştır. Otelin tüm bakım ve tadilat işleri
                de devam etmektedir.
              </p>
              <NavLink
                to="/rixos-the-palm-dubai"
                className="site-button btn-half"
              >
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide1})` }}>
            <div className="overlay">
              <h2>ESENYURT</h2>
              <h1>ABDİ İBRAHİM</h1>
              <p>
                Abdi İbrahim İlaç'ın tüm zemin epoksi ve terrazzo işleri SFS
                Floor tarafından yapılmaktadır. Yeni üretim tesisleri de dahil
                olmak üzere, bakım ve onarım işleri devam etmektedir.
              </p>
              <NavLink
                to="/abdi-ibrahim-ilac-yut"
                className="site-button btn-half"
              >
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide5})` }}>
            <div className="overlay">
              <h2>İSTANBUL</h2>
              <h1>KOÇ LİSESİ</h1>
              <p>
                Koç Lisesi'nin tüm epoksi terrazzo imalatları SFS Floor
                tarafından yapılmıştır.
              </p>
              <NavLink to="/koc-lisesi" className="site-button btn-half">
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide6})` }}>
            <div className="overlay">
              <h2>RUSYA</h2>
              <h1>KAZAN PALACE</h1>
              <p>
                Kazan Palace by Tasigo otelinin tüm duvar ve zeminleri
                microtopping kaplama yapılmıştır. Atrium zemin çimento terrazzo,
                spa zeminler çimento terrazzo, duvarlar ise epoksi prekast
                terrazzo SFS Floor tarafından gerçekleştirilmiştir.
              </p>
              <NavLink
                to="/kazan-palace-by-tasigo-rusya"
                className="site-button btn-half"
              >
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide9})` }}>
            <div className="overlay">
              <h2>KAZAKİSTAN</h2>
              <h1>RIXOS PREMIUM AKTAU</h1>
              <p>
                Rixos Premium Aktau'nun bütün havuzları, yürüyüş yolları, tema
                parkları, SFS Floor tarafından yapılmıştır,aynı zamanda tüm
                bakım tadilatları revizyon dönemlerinde devam etmektir.
              </p>
              <NavLink
                to="/rixos-premium-aktau"
                className="site-button btn-half"
              >
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide11})` }}>
            <div className="overlay">
              <h2>KURUÇEŞME</h2>
              <h1>DİVAN</h1>
              <p>
                Koç Holding ile yıllardır süregelen başarılı iş birlikteliğimiz,
                grubun Kuruçeşme Divan projesinde de devam etmiştir. Kuruçeşme
                Divan projesinin çimento terrazzo imalatları, SFS Endüstriyel
                Zemin Kaplamaları tarafından yapılmıştır.
              </p>
              <NavLink to="/kurucesme-divan" className="site-button btn-half">
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide10})` }}>
            <div className="overlay">
              <h2>ANTALYA</h2>
              <h1>DELPHIN BE GRAND</h1>
              <p>
                Delphin Be Grand Resort Otel'in havuz kaplaması, SFS Endüstriyel
                Zemin Kaplamaları tarafından SFS Pebble Pool Sistem kullanılarak
                yapılmıştır.
              </p>
              <NavLink
                to="/delphin-be-grand-resort"
                className="site-button btn-half"
              >
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slide" style={{ backgroundImage: `url(${slide12})` }}>
            <div className="overlay">
              <h2>KIBRIS</h2>
              <h1>MERİT PARK HOTEL</h1>
              <p>
                Merit Park Otel'in Infinity pool kaplaması, SFS Endüstriyel
                Zemin Kaplamaları tarafından SFS Pebble sistemi kullanılarak
                yapılmıştır. Ayrıca, otelin bar alanlarının zemin ve duvar
                kaplamaları da estetik ve dayanıklılığıyla öne çıkan SFS
                Microloft sistemi ile tamamlanmıştır.
              </p>
              <NavLink
                to="/merit-park-hotel-kibris"
                className="site-button btn-half"
              >
                <span>Proje Detayları</span>
              </NavLink>
            </div>
          </div>
        </div>
      </OwlCarousel>
      <div className="scroll-down-indicator">
        <a href="#kurumsal" className="scroll-down">
          <i className="fas fa-chevron-down"></i>
        </a>
      </div>
    </div>
  );
};

export default OwlCarouselSlider;
