import React from "react";

const Loader = () => {
  return (
    <div className="loading-area">
      <div className="loading-box" />
      <div className="loading-pic">
        <div className="cssload-spinner">
          <img src={require("./../../images/sfs.png")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
