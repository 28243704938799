import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import referanslar from "./../data/referanslar.json";

const OneCikanProjeler = (props) => {
  // isActive değeri true olan projeleri filtreleyin
  const activeProjects = referanslar.filter((project) => project.isActive);

  const options = {
    loop: false,
    rewind: true,
    autoplay: false,
    autoPlay: 7000,
    center: false,
    items: 3,
    margin: 40,
    nav: true,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left"></i>',
      '<i class="fa fa-angle-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },
      640: {
        items: 2,
        margin: 15,
      },
      768: {
        items: 2,
        margin: 15,
      },
      991: {
        items: 3,
        margin: 15,
      },
      1200: {
        items: 3,
      },
    },
  };
  const imagePath = "/assets/media/images/referanslar/";

  return (
    <div
      className={`${props.bgcolor} section-full mobile-page-padding bg-white  p-t80 p-b80`}
    >
      <div className="container">
        <div className="section-content">
          {/* TITLE START */}
          <div className="section-head">
            <div className={`${props.alignment} sx-separator-outer`}>
              <div className="sx-separator bg-white bg-moving bg-repeat-x">
                <h3 className="sep-line-one">{props.title}</h3>
              </div>
            </div>
          </div>
          {/* TITLE END */}
          <div className="work-carousel-outer">
            <OwlCarousel
              className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center p-lr80"
              {...options}
            >
              {activeProjects.map((item, index) => {
                const caption =
                  item.desc.length > 150
                    ? item.desc.substring(0, 150) + "..."
                    : item.desc;

                return (
                  <div key={index} className="item">
                    <div className="project-mas hover-shadow m-a30">
                      <div className="image-effect-one">
                        <img
                          src={`${imagePath}${item.slug}/1.jpg`}
                          alt={item.projeadi}
                        />

                        <div className="figcaption">
                          <a className="mfp-link2" href={`/${item.slug}`}>
                            <div>
                              <span className="px-2">Proje Detayları</span>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="project-info p-a20 bg-gray">
                        <h4 className="sx-tilte m-t0">
                          <NavLink to={`/${item.slug}`}>
                            {item.projeadi}
                          </NavLink>
                        </h4>
                        <p>{caption}</p>
                        <NavLink to={`/services-detail`}>
                          <i className="link-plus bg-primary" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneCikanProjeler;
